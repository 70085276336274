.site-portfolio {
	position: relative;
	z-index: 1;
	overflow: visible;
	height: auto;
	width: 100%;
	// max-height: 100vh;
	padding: 50px 0;

	.loading {
		opacity: 0;
		visibility: hidden;
		// display: block;
		@include position(absolute, -20% -20% -20% -20%);
		@include transition(background-color, 0.6s, ease);
		min-height: 700px;
		pointer-events: none;
		z-index: 1;

		img {
			height: 100px;
			width: 100px;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		}
	}

	&.loading-ajax {
		.loading {
			opacity: 1;
			// display: block;
			visibility: visible;
			content: " ";
			background-color: rgba(0,0,0,0.8);

		}
	}



	//Content
	.portfolio-content {
		@include position(absolute, 50% null null 50%);
		@include transform(translateY(-50%));
		width: 35%;
		max-width: 400px;

		


		h2 {
			color: $light-gray;
			font-size: 65px;
			line-height: 60px;
			margin-bottom: 40px;
			font-family: 'ag bold';
			
			strong {
				display: block;
				color: $dark-gray;
			}
		}
		p {
			color: $dark-gray;
			font-weight: 800;
			letter-spacing: 1px;
		}
		ul {
			color: $dark-gray;
			font-weight: 800;
			list-style-type: disc;
			margin-left: 20px;
			padding: 15px 0;
		}
	}

	//Portfolio Grid
	.grid-sizer {
		width: 25%;
	}
	.portfolio-grid {
		position: relative;
		margin: -5% 0 -5% -5%;
		display: block;
		width: 60%;
		max-width: 100vh;

		ul {
			@extend %zero-font;
			opacity: 0;
			@include transition(opacity 0.1s ease);

			&.is-initialized {
				opacity: 1;
			}
		}

		li {
			display: block;
			vertical-align: middle;
			width: 25%;
			height: 0;
			padding-bottom: 25%;
			@include transition(opacity 0.3s ease);
			opacity: 0;
		}

		.tile {
			@include position(absolute, 5px 5px 5px 5px);
			@include transition(all 0.3s ease);
			overflow: hidden;

			img {
				width: 100%;
				height: auto;
				@include transform(scale(1));
				@include transition(all 0.3s ease);

				&:hover {
					@include transform(scale(1.1));
				}
			}

			//Colours
			&__red { background-color: #FF0000; }
			&__purple { background-color: #7c51a1; }
			&__pink { background-color: #c33093; }
			&__green { background-color: #8dc63f; }
			&__blue { background-color: #30a4dc; }
			&__yellow { background-color: #fff44e; }
			&__yellowlego { background-color: #ffd500; }
			&__orange { background-color: #f9c600; }

			&:active {
				@include transform(scale(0.95));
			}

			&:before,
			&:after {
				content: " ";
				@include position(absolute, 0 0 0 0);
				opacity: 0;
				@include transition(opacity 0.3s ease);
			}

			&:before {
				z-index: 3;
				background: rgba(255, 255, 255, 0.5);
			}
			&:after {
				z-index: 4;
				// background: url(../img/ajax-loader.png) center center no-repeat;
				background-size: 50% 50%;
				@include animation(ajax-spinner 2s linear 0s infinite);
			}


			//Loading state
			&.is-loading {
				@include transition-delay(none !important);
				@include transform(scale(0.75));

				&:before, &:after { opacity: 1; }
			}
		}
		//Random transition delays
		ul.is-initialized li {
			opacity: 1;

			@for $i from 1 through 20 {
				&:nth-child(#{$i}) {
					// $delay: random() / 4;
					$delay: (random(200) + 200) / 1000;
					@include transition-delay(#{$delay}s);
				}
			}
		}
	}

	//Hidden states
	.portfolio-content.is-hidden {
		opacity: 0;

		.cssanimations & {
			opacity: 1;
			@include animation(portfolio-contentFadeOut 0.4s ease 0s forwards);
		}
	}
	.portfolio-grid.is-hidden {
		opacity: 0;

		.cssanimations & {
			opacity: 1;
			@include animation(portfolio-gridFadeOut 0.4s ease 0s forwards);
		}
	}

	//Client Case Study
	.portfolio-client {
		position: relative;
		display: none;
		width: 100%;
		z-index: 5;
		padding: 150px 0 50px;

		&:before,
		&:after {
			content: " ";
			opacity: 0;
			visibility: hidden;
			@include transition(opacity 0.3s ease);
		}

		&:before {
			@include position(absolute, -100px -100px -100px -100px);
			z-index: 3;
			background: rgba(255, 255, 255, 0.75);
		}
		&:after {
			@include position(absolute, 0 0 0 0);
			z-index: 4;
			// background: url(../img/ajax-loader.png) center center no-repeat;
			background-size: 100px 100px;
			@include animation(ajax-spinner 2s linear 0s infinite);
		}


		&.is-loading {
			&:before, &:after { opacity: 1; visibility: visible; }
		}

		.slant {
			@include position(absolute, -100px -500px -100px 40%);
			z-index: 0;
			display: block;
			@include transform(skew(-30deg, 0));

			@include transition(all 0.4s ease);
		}
		.do-close-client {
			@include position(absolute, 0 null null 20px);
			@extend %zero-font;
			display: block;
			width: 50px;
			height: 50px;
			@include transform(rotate(45deg));

			&:before, &:after {
				content: " ";
				@include position(absolute, 50% null null 0);
				margin-top: -1px;
				display: block;
				background: $light-gray;
				width: 100%;
				height: 2px;
			}
			&:after {
				@include transform(rotate(90deg));
			}
		}
		.nav-arrow {
			@include position(absolute, 50% null null null);
			overflow: hidden;
			z-index: 2;
			margin-top: -50px;
			display: block;
			width: 100px;
			height: 100px;
			border-radius: 100px;
			box-shadow: 0 4px 4px rgba($white, 0.35) inset, 0 1px 0 rgba(#000, 0.4);
			@include background-image(linear-gradient(to top, #d0d1d2, #e6e7e8));
			@extend %zero-font;

			&:hover {
				@include background-image(linear-gradient(to top, darken(#d0d1d2, 10%), darken(#e6e7e8, 10%)));
			}

			&:before {
				content: " ";
				@include position(absolute, 0 null 0 0);
				display: block;
				width: 100px;
				height: 100px;
				background: url(../img/portfolio/sprite-nav-arrows.png) 0 0 no-repeat;
				background-size: 200px 100px;
			}

			&.prev {
				left: 60px;
			}
			&.next {
				right: 60px;
				&:before {
					left: auto;
					right: 0;
					background-position: -100px 0;
				}
			}
		}

		.row {
			max-width: 1640px;
			padding: 0 120px;
		}

		.carousel-wrap {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 60%;

			@include transition(all 0.4s ease);
			opacity: 1;
			@include transform(translate(0, 0));
		}
		.client-content {
			position: relative;
			float: right;
			display: inline-block;
			vertical-align: middle;
			width: 40%;
			max-width: 425px;
			margin-left: auto;

			@include transition(all 0.4s ease);
			opacity: 1;
			@include transform(translate(0, 0));
		}

		.carousel-thumbnails {
			display: block;
			padding-top: 40px;
			text-align: center;
			@extend %zero-font;

			li {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				overflow: hidden;
				width: 31.33%;
				max-width: 150px;
				height: 112px;
				line-height: 112px;
				background: $white;
				border: 4px solid #e3e4e5;
				border-radius: 5px;
				margin: 1%;
				padding: 5px;

				&:before {
					content: " ";
					@include position(absolute, 5px 5px 5px 5px);
					display: block;
					opacity: 0;
					border: 2px solid #3b393a;
					border-radius: 4px;
					@include transition(opacity 0.3s ease);
				}


				img {
					display: inline-block;
					vertical-align: middle;
				}

				.hover {
					@include position(absolute, 0 0 0 0);
					z-index: 2;
					margin: auto;
					display: block;
					width: 60px;
					height: 60px;
					border-radius: 50%;
					@include background-image(linear-gradient(to top, rgba(#7c52a3, 0.9), rgba(#5f74bc, 0.9)));
					@include transition(all 0.3s ease);
					opacity: 0;
					@include transform(translateY(50%));

					&:after {
						content: " ";
						@include position(absolute, 0 0 0 0);
						display: block;
						background: url(../img/portfolio/icon-eye.png) center center no-repeat;
						background-size: 29px 20px;
					}
				}
				&:hover {
					cursor: pointer;
					.hover {
						opacity: 1;
						@include transform(translateY(0%));
					}
				}
				&.is-active {
					&:before { opacity: 1; }
					.hover { display: none; }
				}
			}
		}
		.client-logo {
			margin-bottom: 40px;
			max-width: 200px;
			height: auto;
		}
		h2 {
			display: block;
			color: $white;
			font: {
				size: 50px;
				family: 'ag bold';
			}
			line-height: 50px;
			margin-bottom: 40px;
		}
		p {
			color: $white;
			font: {
				size: 16px;
				family: 'ag medium';
			}
			line-height: 24px;
			margin-bottom: 15px;
		}
	}
}