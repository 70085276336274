.site-footer {
	position: relative;
	z-index: 2;
	height: auto;
	width: 100%;
	min-height: 100vh;
	padding-top: 120px;

	.slant {
		display: block;
		@include position(absolute, -50px 0 -200px 0);
		@include transform(skewY(-5deg));
		@include background-image(linear-gradient(to top, #333132, #3b393a));

		.row {
			text-align: right;
			@include transform(skewY(5deg));
		}
	}

	//Social Icons
	.social-icons {
		display: inline-block;
		margin-top: -60px;
		text-align: right;
		float: right;
		min-width: 322px;

		ul {
			@extend %zero-font;
			letter-spacing: -1px;
		}
		li {
			display: inline-block;
			vertical-align: middle;
			margin: 0 -1px;
		}
		a {
			display: block;
			width: 54px;
			height: 54px;
			border: 4px solid $white;
			border-radius: 50%;
			background: #000 url(../img/footer/sprite-footer-social.png) 0 0 no-repeat;
			background-size: 276px 46px;
			box-shadow: 0 3px 0 rgba(255,255,255,0.2) inset;
			@include transform(scale(1));
			@include transition(all 0.3s ease);

			&:hover {
				@include transform(scale(1.1));
			}

			&.twitter {
				background-color: #22a4d0;
				background-position: 0 0;
			}
			&.facebook {
				background-color: #154383;
				background-position: -46px 0;
			}
			&.linkedin {
				background-color: #2f8cc2;
				background-position: -92px 0;
			}
			&.pinterest {
				background-color: #c21111;
				background-position: -138px 0;
			}
			&.google-plus {
				background-color: #c83721;
				background-position: -184px 0;
			}
			&.vimeo {
				background-color: #55c68d;
				background-position: -230px 0;
			}
		}
		h5 {
			display: block;
			color: $white;
			font: {
				size: 25px;
				family: 'ascutas';
			}
			line-height: 35px;
			margin: 20px 50px 0 0;
			padding-right: 75px;
			background: url(../img/footer/icon-footer-social-arrow.png) center right no-repeat;
			background-size: 65px auto;
		}
	}

	//Footer top info
	.footer-info {
		padding-bottom: 100px;

		a {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			padding: 0 25px 0 60px;
			color: $white;
			font: {
				size: 25px;
				family: 'ag book';
			}
			line-height: 44px;

			&:before {
				content: " ";
				@include position(absolute, 0 null null 0);
				display: block;
				width: 39px;
				height: 44px;
				background: url(../img/footer/sprite-contact-icons.png) 0 0 no-repeat;
				background-size: 78px 44px;
			}

			&.telephone:before {
				background-position: 0 0;
			}
			&.email-address:before {
				background-position: -39px 0;
			}
		}
	}
	address {
		display: block;
		padding: 0 0 30px 70px;
		font-style: normal;
		background: url(../img/footer/icon-address-pointer.png) left top no-repeat;
		background-size: 50px auto;

		h2 {
			display: block;
			color: $blue;
			font: {
				size: 35px;
				family: 'ag xlight';
			}
			line-height: 40px;
			padding-bottom: 5px;
		}
		p {
			display: block;
			margin: 0;
			color: $white;
			font: {
				size: 18px;
				family: 'ag book';
			}
			line-height: 22px;
		}
	}
	h3 {
		position: relative;
		color: #b4b4b4;
		font: {
			size: 20px;
			family: 'ag book';
		}
		line-height: 22px;
		padding: 0 100px 0 0;
		margin-bottom: 15px;

		strong {
			display: block;
			color: $white;
			font: {
				size: 45px;
				family: 'ag xlight';
				weight: normal;
			}
			line-height: 45px;
		}
		&:after {
			content: " ";
			@include position(absolute, null 0 5px null);
			display: block;
			width: 116px;
			height: 38px;
		}
	}
	.mailing {
		&:after {
			content: " ";
			@include position(absolute, 5px -20px null null);
			display: block;
			height: 100%;
			width: 3px;
			border-left: 1px solid rgba($white, 0.3);
			background-color: rgba(#000, 0.5);
		}
		h3:after {
			background: url(../img/footer/icon-mail.png) right center no-repeat;
			background-size: 100% auto;
		}
		p {
			color: $white;
			margin: 0 0 25px;
			font: {
				size: 15px;
				family: $helvetica;
			}
			line-height: 20px;

			&.form-response {
				display: none;

				&.form-success {
					font: {
						size: 30px;
						family: 'ag xlight';
					}
					line-height: 35px;
					margin: 40px 0 0;
				}
				&.form-error {
					margin: -15px 0 15px;
					color: red;
				}
			}
		}
	}
	.tweets {
		position: relative;
		padding-left: 45px;

		h3:after {
			background: url(../img/footer/icon-twitter.png) right center no-repeat;
			background-size: 46px auto;
		}
	}

	.tweets {
		position: relative;
		padding-left: 45px;
		h3:after {
			background: url(../img/footer/icon-twitter.png) right center no-repeat;
			background-size: 46px auto;
		}
		.tweet-wrap {
			max-height: 285px;
			overflow-y: scroll;
			.twitter {
				margin-bottom: 30px;
				.userimg {
					background-clip: padding-box;
					border: 2px solid #fff;
					border-top-left-radius: 50%;
					border-top-right-radius: 50%;
					border-bottom-left-radius: 50%;
					border-bottom-right-radius: 50%;
				}
				.username {
					color: #fff;
					display: inline-block;
					width: auto;
					margin-bottom: 10px;
					padding-right: 40px;
					.tweet-at {
						font-size: 80%;
						color: #b7b7b7;
					}
				}
				.date {
					position: absolute;
					top: 0;
					right: 3px;
					font-size: 80%;
				}
				.twittertext {
					font-family: "ag book";
				}
				a {
					color: #fff;
				}
			}
			&::-webkit-scrollbar {
				width: 10px;
				background-color: transparent;
				-webkit-border-radius: 100px;
				z-index: 9;
				position: relative;
				&:hover {
					background-color: rgba(0, 0, 0, 0.09);
				}
			}
			&::-webkit-scrollbar-thumb {
				-webkit-transition: all, 0.3s, ease;
				-moz-transition: all, 0.3s, ease;
				transition: all, 0.3s, ease;
				&:vertical {
					background: rgba(0, 0, 0, 0.5);
					-webkit-border-radius: 100px;
					background-clip: padding-box;
					border: 2px solid transparent;
					min-height: 10px;
					&:active {
						background: rgba(255, 255, 255, 0.61);
						-webkit-border-radius: 100px;
					}
				}
			}
		}
	}

	//Form

	form {
		position: relative;

		&:after {
			@include transition(all 0.2s ease);
			content: " ";
			@include position(absolute, 0 0 0 0);
			@include transform(scale(0));
			opacity: 0;
			visibility: hidden;
			display: block;
			width: 100%;
			height: 100%;
			background: url(../img/ajax-loader.png) center center no-repeat;
			@include animation(ajax-spinner 2s linear 0s infinite);
		}

		&.is-sending {
			* {
				opacity: 0.5;
			}
			&:after {
				visibility: visible;
				@include transform(scale(1));
				opacity: 1;
			}
		}
	}

	.input-wrap {
		position: relative;
		display: block;
		width: 100%;
		margin-bottom: 20px;

		&:after {
			content: " ";
			@include position(absolute, 0 20px null null);
			z-index: 2;
			display: block;
			width: 55px;
			height: 55px;
			background: url(../img/footer/icon-form-input.png) right center no-repeat;
			background-size: 55px auto;
		}

		input {
			position: relative;
			z-index: 1;
			display: block;
			width: 100%;
			height: 55px;
			border: 2px solid $white;
			border-radius: 60px;
			background: none;
			padding: 0 80px 0 140px;

			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 1000px #333132 inset;
				-webkit-text-fill-color: $light-gray;
			}

			&:focus {
				+ label:not(.error) {
					background-color: $white;
					color: #000;
				}
			}

			&.error {
				border-color: red;
				+ label:not(.error) { border-color: red; }
			}
		}
		label {
			@include position(absolute, 0 null null 0);
			z-index: 2;
			display: block;
			background-color: $light-gray;
			height: 55px;
			width: 130px;
			padding-left: 20px;
			color: #333;
			font: {
				size: 15px;
				family: 'ag medium';
			}
			line-height: 55px;
			border-radius: 60px 0 0 60px;
			border: 2px solid $white;
			border-right: none;

			&.error {
				@include position(relative, auto null null auto);
				color: red;
				background: none;
				height: auto;
				width: 100%;
				padding: 10px 0 0 20px;
				margin-bottom: -10px;
				line-height: 20px;
				border-radius: 0;
				border: none;
			}
		}
	}
	button {
		width: 100%;
		max-width: 195px;
		margin-top: 10px;
	}
}

#map {
    width: 100%;
    min-height: 500px;
    height: 500px;
    text-align: center;
    position: relative;
    margin-top: 40px;
    border-top: 8px solid rgb(34, 34, 34);
}