
/* Content Styling */
body {
	background-color: $white;
}
body, .wrapper {
	width: 100%;
	max-width: 100%;
	min-height: 100%;
}

.site-wrapper {
	overflow: hidden;
}

%zero-font {
	font-size: 0;
	text-indent: -999em;
}

/**
 * Header / Hero
 */
@import "modules/header";

/**
 * Portfolio
 */
@import "modules/portfolio";

/**
 * Footer
 */
@import "modules/footer";

/**
 * Site Animations
 */
@import "modules/animations";