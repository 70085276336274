body {
	@include font-feature-settings("kern", "liga", "pnum");
	-webkit-font-smoothing: antialiased;
	color: $base-font-color;
	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $heading-font-family;
	margin: 0;
}

p {
	margin: 0;
}

a {
	text-decoration: none;

	&:active,
	&:focus {
		outline: none;
	}
}

img,
picture {
	margin: 0;
	max-width: 100%;
	height: auto;
	outline: none;
	border: none;
}
