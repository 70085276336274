fieldset {
}

input,
label,
select {
	display: block;
	font-family: 'ag medium';
	font-size: em(15);
}

label {
}

#{$all-text-inputs},
select[multiple=multiple],
textarea,
button {
	&:focus {
		outline: none;
	}
}

textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
}

input[type="file"] {
}

select {
}
