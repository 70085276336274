@import url(https://fonts.googleapis.com/css?family=Roboto:100,900);

/**
 * Normalize or Reset always called first, set the scene
 */
@import "components/normalize"; //Normalize

/**
 * Bourbon and Bitters need to be called, in a set order
 */
@import "bourbon/bourbon"; //Bourbon
@import "base/base"; //Bitters

/**
 * Foundation 5 SCSS Framework
 * Override foundation variables
 */
$row-width: rem(1020px);
$total-columns: 12;
$column-gutter: rem(40px); //Gutter * 2
@import "foundation/foundation"; // Foundation Framework

/**
 * Snapping Point
 * @type {Pixel value}
 */
$width-xsmall: 400px;
$width-small: 640px;
$width-custom: 768px;
$width-medium: 1023px;
$width-large: 1440px;

//Define whether you require custom snapping point
$custom-width-active: true !default;

/**
 * Custom Plugins
 */
@import "components/slick"; // Slick Carousel

/**
 * Main Stylesheet and Media Queries
 * Always call last, in this order, to allow overrides
 */
@import "style"; //Main Stylsheet
@import "media-queries"; //Media Queries