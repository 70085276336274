#{$all-button-inputs},
button {
	@include appearance(none);
	-webkit-font-smoothing: antialiased;
	border-radius: 50px;
	border: 2px solid $yellow;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-family: 'ag medium';
	font-size: 20px;
	line-height: $base-line-height;
	padding: 9px 6px;
	text-decoration: none;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	@include linear-gradient(to top, $yellow, #fedf83);
	text-align: center;
	color: #333;

	&:hover,
	&:focus {
		border-color: darken($yellow, 10%);
		@include linear-gradient(to top, darken($yellow, 10%), darken(#fedf83, 10%));
	}

	&:disabled {
		cursor: not-allowed;
	}
}
