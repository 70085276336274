// Custom Fonts
@include font-face("ascuteas", "../fonts/ascuteas-webfont");
@include font-face("ag bold", "../fonts/avantgardeitcbybt-bold-webfont");
@include font-face("ag demi", "../fonts/avantgardeitcbybt-demi-webfont");
@include font-face("ag book", "../fonts/avantgardeitcbybt-book-webfont");
@include font-face("ag medium", "../fonts/avantgardeitcbybt-medium-webfont");
@include font-face("ag xlight", "../fonts/avantgardeitcbybt-extralight-webfont");

// Typography
$base-font-family: 'ag xlight';
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: em(16);

// Line height
$base-line-height: em(24);
$heading-line-height: em(20);

// Colors
$blue: #00aeef;
$yellow: #fecb36;
$white: #fff;
$darkest-gray: #222222;
$dark-gray: #555555;
$light-gray: #b7b7b7;

// Font Colors
$base-background-color: $white;
$base-font-color: $light-gray;