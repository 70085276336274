/**
 * Slick Carousel Styles
 * Only compatible when using Bourbon due to mixin usage
 * Dependancies: Bourbon
 */

.slick-slider {
    position: relative;
    display: block;
	@include prefixer(box-sizing, 'border-box', webkit moz);
    @include user-select(none);
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
    &:hover {
        cursor: -webkit-grab;
    }
    &:active {
        cursor: -webkit-grabbing;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	@include transform(translate3d(0, 0, 0));
}

.slick-track {
	@include position(relative, 0 null null 0);
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}