/**
 * Main Media Queries
 * Variables at the top define snapping points
 * Media queries are constructed from initial variables
 * Dependancies: Bourbon
 */

/**
 * Xtra Small
 */
@media only screen and (max-width: rem($width-xsmall)) {
	.site-header .slant { bottom: -20px; }
	.site-footer .slant { top: -20px; }
	.site-portfolio { padding: 10px 0; }

	.site-header nav { padding: 10px; }
	.site-header h2.strapline { margin-left: 5px; font-size: 18px; }
	.site-header .site-menu a { padding: 10px 15px; font-size: 18px; }
	.site-header .logo { top: -50px; margin-bottom: -70px; }
	.site-header .client-logos li { padding: 10px; }

	.site-portfolio .portfolio-grid { margin-bottom: -1% !important; }
	.site-portfolio .portfolio-grid .tile { @include position(absolute, 2px 2px 2px 2px); }
	.site-portfolio .portfolio-content { padding: 40px 15px 20px; }
	.site-portfolio .portfolio-content h2 { font-size: 30px; line-height: 30px; margin-bottom: 20px; }
	.site-portfolio .portfolio-content p { font-size: 14px; line-height: 20px; }

	.site-portfolio .portfolio-client .row { padding: 0 10px; }
	.site-portfolio .portfolio-client .carousel-thumbnails li { max-width: 90px; height: 67px; line-height: 57px; }

	.site-footer { padding-top: 100px; }
	.site-footer .social-icons { display: block; float: none; text-align: center; }
	.site-footer .social-icons h5 { margin-top: 10px; text-align: right; }
	.site-footer .footer-info { padding-bottom: 10px; }
	.site-footer address { padding-bottom: 15px; padding-left: 50px; background-size: 40px auto; }
	.site-footer address h2 { font-size: 22px; line-height: 26px; }
	.site-footer address p { font-size: 14px; line-height: 18px; }
	.site-footer .footer-info a { font-size: 18px; padding: 0 0 10px 50px; }
	.site-footer .footer-info a:before { width: 30px; height: 34px; background-size: 60px 34px; top: 5px; }
	.site-footer .footer-info a.email-address:before { background-position: -30px 0; }

	.site-footer h3 { font-size: 17px; line-height: 20px; padding-right: 60px; margin-bottom: 10px; }
	.site-footer h3 strong { font-size: 32px; line-height: 38px; }
	.site-footer .mailing h3:after { background-size: 87px auto; }
	.site-footer .tweets h3:after { background-size: 37px auto; }
	.site-footer .mailing p { font-size: 12px; }
	.site-footer button { margin-bottom: 0; }
}

@media only screen and (min-width: rem($width-xsmall + 1)) {
	.site-footer .social-icons { padding-right: 10px; }
}

@media only screen and (min-width: rem($width-xsmall + 1)) and (max-width: rem($width-small)) {
	.site-header h2.strapline { margin-left: 10px; font-size: 24px; }
	.site-header .logo { top: -110px; margin-bottom: -180px; }
	.site-header .client-logos li { padding: 15px; }

	.site-portfolio .portfolio-content { padding: 40px 15px 20px; }
	.site-portfolio .portfolio-content h2 { font-size: 40px; line-height: 40px; margin-bottom: 20px; }
	.site-portfolio .portfolio-content p { line-height: 20px; }

	.site-portfolio .portfolio-client .row { padding: 0 20px; }
	.site-portfolio .portfolio-client .carousel-thumbnails li { max-width: 120px; height: 90px; line-height: 80px; }

	.site-footer { padding-top: 80px; }
	.site-footer .footer-info { padding-bottom: 50px; }
	.site-footer address { padding-bottom: 25px; padding-left: 65px; }
	.site-footer address h2 { font-size: 27px; line-height: 33px; }
	.site-footer address p { font-size: 15px; line-height: 19px; }
	.site-footer .footer-info a { font-size: 21px; padding: 0 20px 10px 65px; }

	.site-footer h3 { font-size: 19px; line-height: 22px; padding-right: 70px; }
	.site-footer h3 strong { font-size: 35px; line-height: 40px; }
	.site-footer .mailing h3:after { background-size: 98px auto; }
	.site-footer .tweets h3:after { background-size: 44px auto; }
	.site-footer .mailing p { font-size: 14px; }

	.site-footer .input-wrap label { font-size: 14px; width: 115px; padding-left: 15px; }
	.site-footer .input-wrap:after { background-size: 45px auto; }
	.site-footer button { margin-top: 5px; line-height: 24px; }
}


/**
 * Small
 */
@media only screen and (max-width: rem($width-small)) {
	.site-header nav { text-align: center; }
	.site-header .site-menu { position: relative; top: auto; right: auto; }
	.site-header .client-logos h3 { font-size: 20px; }

	.site-portfolio .portfolio-client .nav-arrow { display: none; }
	.site-portfolio .portfolio-client .carousel-thumbnails { padding-top: 20px; }
	.site-portfolio .portfolio-client .client-content { padding-top: 20px; }
	.site-portfolio .portfolio-client .client-logo { max-width: 120px; margin-bottom: 15px; }
	.site-portfolio .portfolio-client h2 { font-size: 28px; line-height: 32px; margin-bottom: 15px; }
	.site-portfolio .portfolio-client p { font-size: 14px; line-height: 20px; }
	.site-portfolio .portfolio-client .do-close-client { top: 10px; left: 10px; width: 40px; height: 40px; }

	.site-footer .footer-info a:before { left: 5px; }
}

@media only screen and (min-width: rem($width-small + 1)) {
}

@media only screen and (min-width: rem($width-small + 1)) and (max-width: rem($width-medium)) {
	.site-portfolio .portfolio-client .row { padding: 0 80px; }
}


@if($custom-width-active) {

/**
 * Custom
 */
@media only screen and (min-width: rem($width-small + 1)) and (max-width: rem($width-custom)) {
	.site-header h2.strapline { font-size: 23px; margin-left: 20px; }
	.site-header .site-menu a { font-size: 18px; padding: 10px; }
	.site-header .logo { top: -110px; margin-bottom: -180px; }
	.site-header .client-logos h3 { font-size: 25px; }

	.site-portfolio .portfolio-content { padding: 40px 20px 30px; }
	.site-portfolio .portfolio-content h2 { font-size: 55px; line-height: 50px; margin-bottom: 30px; }

	.site-portfolio .portfolio-client .client-content { padding-top: 30px; }
	.site-portfolio .portfolio-client .client-logo { max-width: 150px; margin-bottom: 20px; }
	.site-portfolio .portfolio-client h2 { font-size: 35px; line-height: 37px; margin-bottom: 20px; }
	.site-portfolio .portfolio-client p { font-size: 15px; line-height: 22px; }

	.site-footer { padding-top: 100px; }
	.site-footer .footer-info { padding-bottom: 50px; }
	.site-footer address h2 { font-size: 30px; }
	.site-footer address p { font-size: 17px; }
	.site-footer .footer-info a { font-size: 22px; }
}

@media only screen and (max-width: rem($width-custom)) {
	.custom-12 { width: 100%; }

	.site-header .logo { position: relative; width: 150%; max-width: none; left: -25%; @include transform(none); }
	.site-header .down-arrow { margin: 13% 0 0 -30px; width: 60px; height: 60px; }
	.site-header .down-arrow:after { width: 34px; height: 30px; }
	.site-header .social-icons { position: relative; top: auto; right: auto; width: 100%; text-align: center; font-size: 0; @include transform(none); }
	.site-header .social-icons li { display: inline-block; vertical-align: middle; width: 25%; max-width: 60px; }
	.site-header .social-icons li {
		.cssanimations & a {
			@include animation(header-socialAnimateMobile .6s ease 1.3s forwards);
			@include transform(translateY(95px));
			opacity: 0;
		}
		@for $i from 1 through 4 {
			.cssanimations &:nth-child(#{$i}) a {
				@include animation-delay(#{($i/10)+0.9}s);
			}
		}
	}
	.site-header .social-icons li a { margin: 10px auto; }
	.site-header .client-logos { position: relative; }
	.site-header .client-logos li { width: 25%; max-width: 140px; }

	.site-portfolio { max-height: 100%; }
	.site-portfolio .portfolio-content { position: relative; top: auto; @include transform(none); left: auto; width: 100%; max-width: 100%; text-align: center; }
	.site-portfolio .portfolio-grid { margin: 0 0 -5%; width: 100%; max-width: 100%; }

	.site-portfolio .portfolio-client .nav-arrow { width: 60px; height: 60px; }
	.site-portfolio .portfolio-client .nav-arrow:before { width: 60px; height: 60px; background-size: 120px 60px; }
	.site-portfolio .portfolio-client .nav-arrow.next:before { background-position: -60px 0; }

	.site-portfolio .portfolio-content ul li { width: auto;	display: block; }

	.site-footer .social-icons { margin-top: 0; }
	.site-footer .footer-info { max-width: 600px; margin: 0 auto; }
	.site-footer .mailing, .site-footer .tweets { max-width: 600px; margin: 0 auto; padding: 20px 0 0; }
	.site-footer .mailing p { margin-bottom: 20px; }
}

@media only screen and (min-width: rem($width-custom + 1)) {
	.site-header .social-icons li {
		.cssanimations & a {
			@include animation(header-socialAnimate .6s ease 1.3s forwards);
			@include transform(translateX(95px) rotate(360deg));
		}
		@for $i from 1 through 4 {
			.cssanimations &:nth-child(#{$i}) a {
				@include animation-delay(#{($i/10)+0.9}s);
			}
		}
	}
	.site-portfolio .portfolio-content {
		.cssanimations & {
			opacity: 0;
			left: 70%;
			@include animation(portfolio-contentFade 0.4s ease 0.3s forwards);
		}
	}
}

@media only screen and (min-width: rem($width-custom + 1)) and (max-width: rem($width-medium)) {
	.site-portfolio .portfolio-content h2 { font-size: 40px; line-height: 34px; margin-bottom: 15px; }
	.site-portfolio .portfolio-content p { font-size: 14px; line-height: 19px; }

	.site-portfolio .portfolio-content ul li { font-size: 14px; line-height: 19px; }

	.site-portfolio .portfolio-client .client-content { padding-top: 40px; }
	.site-portfolio .portfolio-client h2 { font-size: 45px; line-height: 45px; margin-bottom: 30px; }

	.site-footer .social-icons { margin-top: -40px; }
	.site-footer h3 { font-size: 18px; padding-right: 80px; }
	.site-footer h3 strong { font-size: 36px; line-height: 40px; }
	.site-footer .mailing h3:after { background-size: 100px auto; }
	.site-footer .tweets h3:after { background-size: 43px auto; }

}

}//custom-width-active


/**
 * Medium
 */
@media only screen and (max-width: rem($width-medium)) {
	.site-portfolio .portfolio-client { padding: 50px 0 50px; }
	.site-portfolio .portfolio-client .slant { @include position(absolute, 50% 0 -100px 0); @include transform(skewY(-5deg)); }
	.site-portfolio .portfolio-client .carousel-wrap, .site-portfolio .portfolio-client .client-content { display: block; width: 100%; max-width: 900px; margin: 0 auto; }
	.site-portfolio .portfolio-client .carousel-wrap { padding-top: 10px; }
	.site-portfolio .portfolio-client .client-content { text-align: center; }

	.site-portfolio .portfolio-client.is-hidden {
		.carousel-wrap {
			opacity: 0;
			@include transform(translateY(-100%));
		}
		.client-content {
			opacity: 0;
			@include transform(translateY(100%));
		}
		.slant {
			top: 100%;
		}
	}
}

@media only screen and (min-width: rem($width-medium + 1)) {
	.site-portfolio .portfolio-client.is-hidden {
		.carousel-wrap {
			opacity: 0;
			@include transform(translateX(-100%));
		}
		.client-content {
			opacity: 0;
			@include transform(translateX(100%));
		}
		.slant {
			left: 100%;
			@include transform(skewX(0deg));
		}
	}
}

@media only screen and (min-width: rem($width-medium + 1)) and (max-width: rem($width-large)) {
}


/**
 * Custom Queries
 */

@media only screen and (min-width: rem($width-medium + 1)) and (max-width: rem(1200px)) {
	.site-portfolio .portfolio-content h2 { font-size: 55px; line-height: 50px; margin-bottom: 30px; }

	.site-portfolio .portfolio-client { padding: 100px 0 50px; }
	.site-portfolio .portfolio-client h2 { font-size: 45px; line-height: 45px; margin-bottom: 30px; }
}
@media only screen and (min-width: rem(1201px)) and (max-width: rem(1700px)) {
	.site-portfolio .portfolio-client { padding: 120px 0 50px; }
	.site-portfolio .portfolio-client h2 { font-size: 55px; line-height: 55px; margin-bottom: 30px; }

	.cssanimations .site-portfolio .portfolio-content { left: 50% }
}


@media only screen and (max-width: rem(1700px)) {
	.site-portfolio .portfolio-client .nav-arrow.prev { left: 20px; }
	.site-portfolio .portfolio-client .nav-arrow.next { right: 20px; }
	.cssanimations .site-portfolio .portfolio-content { margin: 0 auto; }
}
