.site-header {
	position: relative;
	z-index: 2;
	height: auto;
	width: 100%;
	min-height: 100vh;

	.slant {
		display: block;
		background: #2dbcff;
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMTAwJSI+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMmRiY2ZmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMjQlIiBzdG9wLWNvbG9yPSIjNDJiZmVkIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNDUlIiBzdG9wLWNvbG9yPSIjM2JhN2VmIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iNjYlIiBzdG9wLWNvbG9yPSIjN2Y2YWJhIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iODQlIiBzdG9wLWNvbG9yPSIjN2M1MmEzIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
		background: -moz-linear-gradient(-45deg,  #2dbcff 0%, #42bfed 24%, #3ba7ef 45%, #7f6aba 66%, #7c52a3 84%);
		background: -webkit-linear-gradient(-45deg,  #2dbcff 0%,#42bfed 24%,#3ba7ef 45%,#7f6aba 66%,#7c52a3 84%);
		background: linear-gradient(135deg,  #2dbcff 0%,#42bfed 24%,#3ba7ef 45%,#7f6aba 66%,#7c52a3 84%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2dbcff', endColorstr='#7c52a3',GradientType=1 );

		@include position(absolute, -200px 0 -50px 0);
		@include transform(skewY(5deg));

		&:after {
			content: " ";
			@include position(absolute, null null -135px 0);
			display: block;
			pointer-events: none;
			width: 100%;
			height: 135px;
			background: url(../img/header/bottom-shadow.png) center bottom no-repeat;
		}
	}

	//Top Navigation
	nav {
		position: relative;
		z-index: 2;
		display: block;
		width: 100%;
		padding: 20px;
	}
	.home-link {
		display: inline-block;
		vertical-align: middle;
		width: 45px;
		height: 45px;
		@extend %zero-font;
		background: url(../img/header/icon-house.png) center center no-repeat;
		background-size: 34px auto;
	}
	h2.strapline {
		display: inline-block;
		vertical-align: middle;
		margin-left: 40px;
		color: rgba(255,255,255,0.3);
		font: {
			family: 'ag bold';
			size: 27px;
		}
		line-height: 45px;

		strong {
			display: inline-block;
			margin: 0 -6px;
			color: $white;
		}
	}
	.site-menu {
		position: absolute;
		top: 20px;
		right: 20px;
		font-size: 0;

		li {
			display: inline-block;
			vertical-align: middle;
		}
		a {
			display: block;
			padding: 10px 20px;
			color: $white;
			font: {
				size: 20px;
				family: 'ag book';
			}
			line-height: 25px;
			text: {
				align: center;
				transform: uppercase;
			}
			&:hover {
				text-decoration: underline;
			}
		}
	}

	//Social Icons
	.social-icons {
		@include position(absolute, 50% 0 null null);
		@include transform(translateY(-50%));
		width: 95px;
		overflow: hidden;

		li {
			display: block;

			a {
				display: block;
				margin: 10px 0;
				width: 40px;
				height: 40px;
				line-height: 40px;
				border: 1px solid $white;
				border-radius: 50%;
				text-align: center;
				transition: all 0.2s ease;

				svg {
					display: inline-block;
					vertical-align: middle;
					fill: $white;
					transition: all 0.2s ease;
				}

				&:hover {
					background-color: $white;
					svg { fill: #7c65b9; }
				}
			}
		}
	}

	//Large Logo
	.logo {
		@include position(absolute, 40% null null 50%);
		@include transform(translate(-50%, -50%));
		width: 100%;
		max-width: 1200px;
		z-index: 0;

		.img {
			&__growing {
				@include position(absolute, 40% null null 28%);
				z-index: 2;
				width: 24%;
				max-width: 268px;

				.cssanimations & {
					opacity: 0;
					@include animation(header-growing 0.5s ease 0.6s forwards);
				}
			}
			&__logo {
				position: relative;
				z-index: 1;
				width: 100%;
				max-width: 100%;

				.js & {
					opacity: 0;
					@include transition(opacity 0.6s ease 0.2s);
					&.imagesloaded { opacity: 1; }
				}
			}
		}
	}
	.down-arrow {
		@include position(absolute, 50% null null 50%);
		z-index: 2;
		margin: 15% 0 0 -40px;
		width: 80px;
		height: 80px;
		display: block;
		border: 2px solid #fff44e;
		border-radius: 50%;
		box-shadow: 0 2px 6px rgba(#000, 0.25), 0 1px 0 rgba(#000, 0.3), 0 6px 6px $white inset;
		@include background-image(linear-gradient(to top, #fff44e, #ffff9c));

		&:after {
			content: " ";
			@include position(absolute, 0 0 0 0);
			width: 43px;
			height: 38px;
			margin: auto;
			display: block;
			background: url(../img/header/icon-down-arrow.png) center center no-repeat;
			background-size: 100% 100%;
		}

		.cssanimations & {
			opacity: 0;
			@include transform(translate3d(0, -40px, 0));
			@include animation(header-downArrow 0.5s ease 1.4s forwards);
		}
	}

	//Clients List
	.client-logos {
		@include position(absolute, null null 0 0);
		width: 100%;
		text-align: center;
		opacity: 0;
		@include transition(opacity 0.3s ease);

		&.imagesloaded {
			opacity: 1;
		}

		ul {
			width: 100%;
			max-width: 1200px;
			margin: 0 auto;
			@extend %zero-font;
		}
		li {
			display: inline-block;
			vertical-align: middle;
			padding: 20px;
			width: 12.5%;
			max-width: 180px;

			.cssanimations & img {
				opacity: 0;
				@include animation(header-clients 0.3s ease 0s forwards);
			}

			@for $i from 1 through 8 {
				.cssanimations &:nth-child(#{$i}) img {
					@include animation-delay(#{($i/10)}s);
				}
			}

		}
		h3 {
			padding: 10px 0 20px;
			color: $white;
			font-size: 30px;
			display: inline-block;
		}
	}
}