/**
 * Naming convention: section-animationName
 * E.g. header-logo
 */
@include keyframes(header-growing) {
	from {
		opacity: 0;
		@include transform(translate3d(-40px, 0, 0));
	}
	to {
		opacity: 1;
		@include transform(translate3d(0, 0, 0));
	}
}

@include keyframes(header-downArrow) {
	from {
		opacity: 0;
		@include transform(translate3d(0, -40px, 0));
	}
	to {
		opacity: 1;
		@include transform(translate3d(0, 0, 0));
	}
}

@include keyframes(header-socialAnimate) {
	from {
		@include transform(translateX(95px) rotate(360deg));
	}
	to {
		@include transform(translateX(0) rotate(0deg));
	}
}
@include keyframes(header-socialAnimateMobile) {
	from {
		@include transform(translateY(95px));
		opacity: 0;
	}
	to {
		@include transform(translateY(0));
		opacity: 1;
	}
}
@include keyframes(header-clients) {
	from {
		opacity: 0;
		@include transform(translateY(10px));
	}
	to {
		opacity: 1;
		@include transform(translateY(0));
	}
}

@include keyframes(portfolio-contentFade) {
	from {
		opacity: 0;
		left: 70%;
	}
	to {
		opacity: 1;
		// left: 63%;
		left: 55%;
		right: 0;
	}
}
@include keyframes(portfolio-contentFadeOut) {
	from {
		opacity: 1;
		left: 63%;
	}
	to {
		opacity: 0;
		// left: 85%;
		left: 55%;
		right: 0;
	}
}
@include keyframes(portfolio-gridFadeOut) {
	from {
		opacity: 1;
		left: 0%;
	}
	to {
		opacity: 0;
		left: -20%;
	}
}

@include keyframes(ajax-spinner) {
	from {
		@include transform(rotate(0deg));
	}
	to {
		@include transform(rotate(360deg));
	}
}



//@media only screen and (min-width: rem($width-medium + 1)) and (max-width: rem(1200px)) {
//	@include keyframes(portfolio-contentFade) {
//		from {
//			opacity: 0;
//			left: 70%;
//		}
//		to {
//			opacity: 1;
//			left: 62%;
//		}
//	}
//	@include keyframes(portfolio-contentFadeOut) {
//		from {
//			opacity: 1;
//			left: 62%;
//		}
//		to {
//			opacity: 0;
//			left: 85%;
//		}
//	}
//}
//
//@media only screen and (min-width: rem($width-custom + 1)) and (max-width: rem($width-medium)) {
//	@include keyframes(portfolio-contentFade) {
//		from {
//			opacity: 0;
//			left: 70%;
//		}
//		to {
//			opacity: 1;
//			left: 58%;
//		}
//	}
//	@include keyframes(portfolio-contentFadeOut) {
//		from {
//			opacity: 1;
//			left: 58%;
//		}
//		to {
//			opacity: 0;
//			left: 85%;
//		}
//	}
//}
